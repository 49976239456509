import React from 'react';
import PropTypes from 'prop-types';

import ItemDataFetcher from '../../api/object-admin/ItemDataFetcher';
import SelectItemType from '../EditForm/Inputs/SelectItemType';
import TargetSelect from '../Utils/TargetSelect';

import UITeaserBox from '../UITeaserBox';

import './styles.scss';

/**
 * @visibleName 2 variable Teaser Boxes
 */
class TeaserBoxListTwo extends React.Component {
    render() {
        const {
            itemType1, itemId1, url1, targetUrl1,
            itemType2, itemId2, url2, targetUrl2,

            imgSrc1, imgAlt1, imgTitle1, title1, text1, linkText1,
            imgSrc2, imgAlt2, imgTitle2, title2, text2, linkText2,

            language, season,
        } = this.props;
        const className = 'teaser-box-listing-two';
        return (
            <div className={className}>
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-col-xl-5 g-offset-xl-1 g-col-lg-3 g-col-md-2 g-col-sm-2">
                            {itemType1 ? (
                                <ItemDataFetcher
                                    itemType={itemType1}
                                    itemId={itemId1}
                                    language={language}
                                    season={season}
                                >
                                    <UITeaserBox modificators={['teaser-two']} url={url1} target={'_self'} />
                                </ItemDataFetcher>
                            ) : (
                                <UITeaserBox
                                    imgSrc={imgSrc1}
                                    imgAlt={imgAlt1}
                                    imgTitle={imgTitle1}
                                    title={title1}
                                    text={text1}
                                    linkText={linkText1}
                                    url={url1}
                                    target={targetUrl1}
                                    modificators={['teaser-two']}
                                />
                            )}
                        </div>

                        <div className="g-col-xl-5 g-col-lg-3 g-col-md-2 g-col-sm-2">
                            {itemType2 ? (
                                <ItemDataFetcher
                                    itemType={itemType2}
                                    itemId={itemId2}
                                    language={language}
                                    season={season}
                                >
                                    <UITeaserBox modificators={['teaser-two']} url={url2} target={'_self'} />
                                </ItemDataFetcher>
                            ) : (
                                <UITeaserBox
                                    imgSrc={imgSrc2}
                                    imgAlt={imgAlt2}
                                    imgTitle={imgTitle2}
                                    title={title2}
                                    text={text2}
                                    linkText={linkText2}
                                    url={url2}
                                    target={targetUrl2}
                                    modificators={['teaser-two']}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

TeaserBoxListTwo.formFields = Object.assign({}, {
    itemType1: <SelectItemType id={'itemType1'} options={['activity']} />,
    itemId1: <input />,
    url1: <input />,
    targetUrl1: <TargetSelect />,

    imgSrc1: <input />,
    imgAlt1: <input />,
    imgTitle1: <input />,
    title1: <input />,
    linkText1: <input />,
    text1: <input />,
    //
    itemType2: <SelectItemType id={'itemType2'} options={['activity']} />,
    itemId2: <input />,
    url2: <input />,
    targetUrl2: <TargetSelect />,

    imgSrc2: <input />,
    imgAlt2: <input />,
    imgTitle2: <input />,
    title2: <input />,
    linkText2: <input />,
    text2: <input />,
});

TeaserBoxListTwo.propTypes = {
    // item1
    itemType1: PropTypes.string,
    itemId1: PropTypes.string,
    url1: PropTypes.string,
    targetUrl1: PropTypes.string,
    imgSrc1: PropTypes.string,
    imgAlt1: PropTypes.string,
    imgTitle1: PropTypes.string,
    title1: PropTypes.string,
    text1: PropTypes.string,
    linkText1: PropTypes.string,
    // item2
    itemType2: PropTypes.string,
    itemId2: PropTypes.string,
    url2: PropTypes.string,
    targetUrl2: PropTypes.string,
    imgSrc2: PropTypes.string,
    imgAlt2: PropTypes.string,
    imgTitle2: PropTypes.string,
    title2: PropTypes.string,
    text2: PropTypes.string,
    linkText2: PropTypes.string,
    // other
    language: PropTypes.string,
    season: PropTypes.string,
};

TeaserBoxListTwo.defaultProps = {
    // item1
    itemType1: '', // activity
    itemId1: '',
    imgSrc1: '/images/chris-holgersson-276772-unsplash.jpg',
    imgAlt1: 'Default alt text',
    imgTitle1: 'Default title text',
    title1: 'Unterkünfte finden & buchen',
    text1: 'Von den höchsten kulinarischen Genüssen über tiefgehende Erholung. Von den höchsten ' +
        'kulinarischen Genüssen über.',
    linkText1: 'mehr erfahren',
    url1: '#',
    targetUrl1: '_self',
    // item2
    itemType2: '',
    itemId2: '',
    imgSrc2: '/images/chris-holgersson-276772-unsplash.jpg',
    imgAlt2: 'Default alt text',
    imgTitle2: 'Default title text',
    title2: 'Events',
    text2: 'Von den höchsten kulinarischen Genüssen über tiefgehende Erholung.',
    linkText2: 'mehr erfahren',
    url2: '#',
    targetUrl2: '_self',
    // other
    language: 'de',
    season: 'winter',
};

export default TeaserBoxListTwo;
